import EnvBanner from '../EnvBanner/EnvBanner';
import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import TopBar from './components/TopBar/TopBar';

type Props = {
  children?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
  title?: string;
};

export default function AppFrame(props: Readonly<Props>) {
  const { breadcrumbs, children, title } = props;

  return (
    <>
      <EnvBanner />
      <TopBar />
      <Main
        breadcrumbs={breadcrumbs}
        title={title}
      >
        {children}
      </Main>
      <Footer />
    </>
  );
}

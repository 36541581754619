import { InfoTooltip } from '@42.nl/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string | undefined;
};

export default function EnumLabel({ value }: Readonly<Props>) {
  const { t } = useTranslation('enum');
  if (!value) {
    return null;
  }

  const label = t(value);
  if (label === value) {
    return value;
  }

  return (
    <>
      <span>{value}</span>
      <InfoTooltip
        tooltip={label}
        className="ms-1 text-primary"
        size={15}
      />
    </>
  );
}

import { useMemo } from 'react';
import { groupBy } from 'lodash';
import Widget, { WidgetType } from '../Widget';

export type GroupedWidgets = {
  primary: Widget[];
  summary: Widget[];
  detail: Widget[];
};

export function useWidgetGroups(widgets: Widget[]): GroupedWidgets {
  return useMemo(() => {
    const groups = groupBy(widgets, (widget) => {
      switch (widget.type) {
        case WidgetType.PRIMARY:
          return 'primary';
        case WidgetType.SIDEBAR:
          return 'detail';
        default:
          return 'summary';
      }
    }) as Partial<GroupedWidgets>;

    return {
      primary: groups.primary || [],
      summary: groups.summary || [],
      detail: groups.detail || []
    };
  }, [widgets]);
}

import SimpleModule from '../types/SimpleModule';
import { moduleDetailUrl } from './ModuleDetail/ModuleDetail';

type ModuleLinkProps = {
  module?: SimpleModule;
};

export default function ModuleLink({ module }: Readonly<ModuleLinkProps>) {
  if (!module?.data) {
    return <></>;
  }

  return <a href={moduleDetailUrl(module.data)}>{module.data.code}</a>;
}

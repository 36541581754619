import { useEffect, useState } from 'react';

type Props = {
  delay: number;
  children: () => React.ReactNode;
};

export function Delayed(props: Readonly<Props>) {
  const { children, delay } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  if (!visible) {
    return null;
  }

  return <div>{children()}</div>;
}

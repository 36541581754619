import { isEmpty, sortBy } from 'lodash';
import AcademicYear from '../types/AcademicYear';
import { getYearService } from '../years/YearService';
import Reference from './Reference';

class ReferenceService {
  private references: { [key: string]: Reference[] } = {};

  constructor() {
    this.loadReferences = this.loadReferences.bind(this);
    this.getActiveReferences = this.getActiveReferences.bind(this);
    this.getReferences = this.getReferences.bind(this);
  }

  async loadReferences() {
    const types = [
      'TIME-BLOCK',
      'FACULTY_DESCRIPTION',
      'STUDY_DESCRIPTION',
      'MODULEGROUP_DESCRIPTION',
      'MODULE_DESCRIPTION',
      'SOFTWARE',
      'PHASE'
    ];

    const promises: Promise<Reference[]>[] = [];
    types.forEach((type) => {
      promises.push(this.getReferences(type));
    });
    return Promise.all(promises);
  }

  private async getReferences(type: string): Promise<Reference[]> {
    if (!this.references[type]) {
      this.references[type] = await findReferences(type);
    }
    return this.references[type];
  }

  async getActiveReferences(
    type?: string,
    year?: string
  ): Promise<Reference[]> {
    if (!type) {
      return [];
    }

    const references = await this.getReferences(type);
    const currentYear = getYearService().getYear(year);
    return references.filter((reference) => isActive(reference, currentYear));
  }
}

function isActive(reference: Reference, year?: AcademicYear) {
  if (!year) {
    return true;
  }
  if (reference.year) {
    return reference.year.externalId === year.externalId;
  }

  const started = !reference.startDate || reference.startDate <= year.endDate;
  const open = !reference.endDate || reference.endDate >= year.startDate;
  return started && open;
}

const instance = new ReferenceService();

export function getReferenceService(): ReferenceService {
  return instance;
}

async function findReferences(type: string) {
  const references = await Reference.findByType(type);
  return sortBy(references, ['sequence', 'externalId']);
}

export function getReferenceById(
  types: Reference[],
  externalId: string | undefined
) {
  if (isEmpty(externalId)) {
    return null;
  }

  return types.find((type) => type.externalId === externalId);
}

import { useEffect, useState } from 'react';
import { AuthenticationState, getService } from '@42.nl/authentication';
import { CurrentUser } from '../users/CurrentUser';
import { getAuthenticationService } from './AuthenticationService';

export function useAuthentication(): AuthenticationState<CurrentUser> {
  const [authentication, setAuthentication] = useState<
    AuthenticationState<CurrentUser>
  >(getService<CurrentUser>().getState());

  useEffect(() => {
    let mounted = true;

    const fetchAuthentication = async () => {
      const data = await getAuthenticationService().getAuthentication();
      if (mounted) {
        setAuthentication(data);
      }
    };

    fetchAuthentication();

    return () => {
      mounted = false;
    };
  }, [authentication]);

  return authentication;
}

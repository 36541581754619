export default class Credits {
  optimum?: bigint;
  minimum?: bigint;
  maximum?: bigint;
}

export function getCredits(credits: Credits | undefined) {
  if (credits) {
    if (credits.optimum !== undefined) {
      return credits.optimum;
    } else if (credits.maximum !== undefined) {
      return credits.maximum;
    }
  }
  return '';
}

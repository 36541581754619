import { Icon } from '@42.nl/ui';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledDropdown
} from 'reactstrap';
import { toAbout } from '../../../../../../About/links';
import { CurrentUser } from '../../../../../../users/CurrentUser';
import { toUsersApp } from '../../../../../../users/links';
import { AdminNavLink } from '../../../../../AdminNavLink/AdminNavLink';
import LogoutDropdownItem from '../LogoutDropdownItem/LogoutDropdownItem';
import AdminNav from './AdminNav';

type Props = {
  user: CurrentUser | undefined;
  isMobile: boolean;
};

export default function UserNav({ user, isMobile }: Readonly<Props>) {
  const { t } = useTranslation(['topbar', 'translation']);

  return user ? (
    <Nav className={isMobile ? 'mobile-menu-extra' : 'd-none d-lg-flex'}>
      <NavItem className="d-flex">
        <AdminNavLink
          to={toUsersApp()}
          icon="people"
          text={t('TOPBAR.USERS')}
          iconClass="me-2"
        ></AdminNavLink>
      </NavItem>
      <AdminNav />
      <UncontrolledDropdown
        nav
        inNavbar
      >
        <DropdownToggle
          nav
          caret
          color="outline-secondary"
        >
          <Icon icon="person" />
          {user.name}
        </DropdownToggle>
        <DropdownMenu>
          <LogoutDropdownItem />
        </DropdownMenu>
      </UncontrolledDropdown>
      <NavItem className="d-flex">
        <AdminNavLink
          to={toAbout()}
          icon="help"
        ></AdminNavLink>
      </NavItem>
    </Nav>
  ) : null;
}

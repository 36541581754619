import { Icon } from '@42.nl/ui';

type Props = {
  checked?: boolean;
};

export function Check({ checked }: Readonly<Props>) {
  if (checked !== true) {
    return null;
  }

  return (
    <Icon
      key="name"
      icon="check"
    />
  );
}

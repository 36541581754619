import i18n from '../../core/i18n';

type Named = {
  code?: string;
  dutchName?: string;
  englishName?: string;
};

export function getName(entity?: Named) {
  if (!entity) {
    return null;
  }

  const name =
    i18n.language === 'nl'
      ? entity.dutchName ?? entity.englishName
      : entity.englishName ?? entity.dutchName;
  return name ?? entity.code;
}

import React, { useEffect, useState } from 'react';
import { Button } from '@42.nl/ui';
import _, { isEmpty, transform } from 'lodash';
import { useQuery } from 'react-query';
import { getText } from '../../i18n/Text';
import Data from '../../types/canonical/Data';
import Product, { ProductType, getTemplate } from '../../types/Product';
import Widget, { getVisibleSortedFields, WidgetType } from '../Widget';
import WidgetField from '../WidgetField/WidgetField';

type Props = {
  product: Product;
  children?: React.ReactNode;
};

export function WidgetButtons({ product, children }: Readonly<Props>) {
  const state = useQuery(
    [WidgetButton.QUERY_KEY, { productType: product.productType }],
    () => loadWidgets(product.productType),
    {
      enabled: !!product.productType
    }
  );

  const [buttons, setButtons] = useState<WidgetButton[]>([]);

  useEffect(() => {
    if (state.data) {
      const transformedButtons = transform(
        state.data,
        (result, widget) => {
          result.push(...getButtons(widget, product.data));
        },
        new Array<WidgetButton>()
      );
      setButtons(transformedButtons);
    }
  }, [state.data, product]);

  return (
    <div className="d-flex align-items-center justify-content-start justify-content-md-end flex-wrap gap--0_4rem">
      {buttons.map((button, index) => (
        <a
          key={index}
          href={button.url}
          target="_blank"
          rel="noreferrer"
          aria-label={button.name}
        >
          <Button
            type="button"
            className="btn-light"
          >
            {button.name}
          </Button>
        </a>
      ))}

      {children}
    </div>
  );
}

async function loadWidgets(productType: ProductType): Promise<Widget[]> {
  const widgets = await Widget.list();
  return _(widgets)
    .filter((widget) => widget.entityType === productType)
    .filter((widget) => widget.type === WidgetType.BUTTONS)
    .filter('visible')
    .sortBy('sequence', 'id')
    .value();
}

export class WidgetButton {
  name?: string;
  url: string;

  constructor(field: WidgetField, data: Data) {
    this.name = getText(field.labels, '?');
    this.url = getTemplate(field.name, data);
  }

  static readonly QUERY_KEY = 'widgetButtons';
}

function getButtons(widget: Widget, data: Data): WidgetButton[] {
  return getVisibleSortedFields(widget)
    .filter((field) => field.type === 'URL')
    .map((field) => {
      return new WidgetButton(field, data);
    })
    .filter((button) => !isEmpty(button.url));
}

import { LanguageLabel } from '../../i18n';
import Product from '../../types/Product';

export default function ProductHeaderFallback({
  product
}: Readonly<{ product: Product }>) {
  return (
    <h1 className="details__content__main-title">
      <span className="pe-1">({product.data.code})</span>
      <LanguageLabel names={product.data.names} />
    </h1>
  );
}

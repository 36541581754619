import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';
import { useAuthentication } from '../../../../../../authentication/useAuthentication';
import { toFilterApp } from '../../../../../../filters/links';
import { toImportApp } from '../../../../../../import/links';
import { toJobApp } from '../../../../../../jobs/links';
import { toLabelsApp } from '../../../../../../labels/links';
import { toParameterApp } from '../../../../../../parameters/links';
import { toPeriodApp } from '../../../../../../periods/links';
import { toReferencesApp } from '../../../../../../references/links';
import { toTabsApp } from '../../../../../../tabs/links';
import { toWidgetsApp } from '../../../../../../widgets/links';
import { toYearsApp } from '../../../../../../years/links';

export default function AdminNav() {
  const { t } = useTranslation(['topbar', 'translation', 'application']);
  const navigate = useNavigate();

  const auth = useAuthentication();
  const isAdmin = auth?.currentUser?.roles.includes('ADMIN');

  const MENU_ITEMS = [
    {
      label: t('TOPBAR.FILTERS'),
      onClick: () => goTo(toFilterApp())
    },
    {
      label: t('TOPBAR.LABELS'),
      onClick: () => goTo(toLabelsApp())
    },
    {
      label: t('TOPBAR.PARAMETERS'),
      onClick: () => goTo(toParameterApp())
    },
    {
      label: t('TOPBAR.REFERENCES'),
      onClick: () => goTo(toReferencesApp())
    },
    {
      label: t('TOPBAR.WIDGETS'),
      onClick: () => goTo(toWidgetsApp())
    },
    {
      label: t('TOPBAR.TABS'),
      onClick: () => goTo(toTabsApp())
    },
    {
      label: t('TOPBAR.IMPORT'),
      onClick: () => goTo(toImportApp())
    },
    {
      label: t('TOPBAR.ACADEMIC_YEARS'),
      onClick: () => goTo(toYearsApp())
    },
    {
      label: t('TOPBAR.PERIODS'),
      onClick: () => goTo(toPeriodApp())
    },
    {
      label: t('TOPBAR.JOBS'),
      onClick: () => goTo(toJobApp())
    }
  ].sort((item1, item2) => item1.label.localeCompare(item2.label));

  function goTo(url: string) {
    navigate(url);
  }

  return isAdmin ? (
    <UncontrolledDropdown
      nav
      inNavbar
    >
      <DropdownToggle
        nav
        caret
      >
        <i className="icon material-icons me-2">settings</i>
        {t('TOPBAR.ADMINISTRATION')}
      </DropdownToggle>
      <DropdownMenu>
        {MENU_ITEMS.map((item, index) => (
          <DropdownItem
            className="clickable"
            onClick={item.onClick}
            key={index}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : null;
}

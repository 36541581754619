import Text from '../../types/canonical/Text';
import { getText } from '../Text';

interface Props {
  names?: Text[];
}

export default function LanguageLabel({ names }: Readonly<Props>) {
  const name = getText(names);
  return <span>{name}</span>;
}

import { isEmpty } from 'lodash';
import { Navigate, RouteProps } from 'react-router';
import { NO_YEARS_APP_URL } from './NoYearsApp';
import { getYearService } from './YearService';

export function YearRoute({ element }: RouteProps) {
  const currentYear = getYearService().getCurrentYear();

  if (!isEmpty(currentYear)) {
    return <>{element}</>;
  } else {
    return <Navigate to={NO_YEARS_APP_URL} />;
  }
}

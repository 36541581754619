import { useEffect, useState } from 'react';
import { Checkbox } from '@42.nl/ui';
import { Field } from 'react-final-form';
import { Text, getText } from '../../i18n/Text';

type OptionProps = {
  id: number;
  name: string;
  labels: Text[];
};

type Props<T> = {
  id: string;
  label: string;
  options: T[] | undefined;
  className?: string;
};

export default function CheckboxMultiSelect<T extends OptionProps>({
  id,
  label,
  options,
  className
}: Readonly<Props<T>>) {
  return (
    <>
      {options?.length ? (
        <div className={className}>
          <label>{label}</label>
          <Field
            name={id}
            component={CheckboxMultiSelectComponent<T>}
            options={options}
          />
        </div>
      ) : undefined}
    </>
  );
}

type CheckboxMultiSelectProps<T> = {
  input: any;
  meta: any;
  options: T[] | undefined;
};

function CheckboxMultiSelectComponent<T extends OptionProps>({
  input,
  options
}: CheckboxMultiSelectProps<T>) {
  const [ids, setIds] = useState<number[]>([]);

  useEffect(() => {
    setIds(
      input.value ? input.value.map((option: { id: any }) => option.id) : []
    );
  }, [input.value, options]);

  function setValue(value: T, checked: boolean) {
    if (checked) {
      input.onChange([...input.value, value]);
    } else {
      input.onChange(
        input.value.filter((widget: { id: number }) => value.id !== widget.id)
      );
    }
  }

  return (
    <>
      {options?.map((option) => (
        <Checkbox
          key={option.id}
          id={`${input.name}-${option.id}`}
          label={getText(option.labels) ? getText(option.labels) : option.name}
          value={ids.includes(option.id)}
          onChange={(checked) => setValue(option, checked)}
        />
      ))}
    </>
  );
}

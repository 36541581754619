import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { getText } from '../../../i18n/Text';
import {
  isEnlargedModuleCell,
  partitionBySize
} from '../../../modules/ModuleCell';
import WidgetField, {
  getWidgetFieldKey
} from '../../../widgets/WidgetField/WidgetField';

const COLUMN_CODE_WIDTH = 15;
const COLUMN_NAME_WIDTH = 27.5;
const ENLARGED_FIELD_WIDTH = 27.5;
const TOTAL_WIDTH = 100;

const createBaseColumns = <T>(
  t: (key: string) => string,
  renderCode: (record: T) => React.ReactNode,
  renderName: (record: T) => React.ReactNode
): ColumnsType<T> => [
  {
    title: t('COLUMNS.CODE'),
    key: 'code',
    width: `${COLUMN_CODE_WIDTH}%`,
    render: (_, record) => renderCode(record)
  },
  {
    title: t('COLUMNS.NAME'),
    key: 'name',
    width: `${COLUMN_NAME_WIDTH}%`,
    render: (_, record) => renderName(record)
  }
];

interface FieldWidthMapping {
  field: WidgetField;
  width: number;
}

const mapFields = (
  fields: WidgetField[],
  priorityFields: WidgetField[],
  normalFields: WidgetField[]
): FieldWidthMapping[] => {
  const remainingWidth =
    TOTAL_WIDTH -
    COLUMN_CODE_WIDTH -
    COLUMN_NAME_WIDTH -
    ENLARGED_FIELD_WIDTH * priorityFields.length;
  const normalWidth = remainingWidth / (normalFields.length || 1);

  return fields.map((field) => ({
    field,
    width: isEnlargedModuleCell(field) ? ENLARGED_FIELD_WIDTH : normalWidth
  }));
};

const createColumn = <T>(
  field: WidgetField,
  width: number,
  renderColumn: (field: WidgetField, record: T) => React.ReactNode
) => ({
  title: getText(field.labels, field.name),
  key: getWidgetFieldKey(field),
  width: `${width.toFixed(2)}%`,
  minWidth: 100,
  render: (_: any, record: T) => renderColumn(field, record)
});

interface UseGroupModuleColumnsProps<GroupModulesTableRecord> {
  fields: WidgetField[];
  renderCode: (record: GroupModulesTableRecord) => React.ReactNode;
  renderName: (record: GroupModulesTableRecord) => React.ReactNode;
  renderColumn: (
    field: WidgetField,
    record: GroupModulesTableRecord
  ) => React.ReactNode;
}

export function useGroupModuleColumns<T>({
  fields,
  renderCode,
  renderName,
  renderColumn
}: UseGroupModuleColumnsProps<T>): ColumnsType<T> {
  const { t } = useTranslation(['module']);

  return useMemo(() => {
    const baseColumns = createBaseColumns(t, renderCode, renderName);
    const [enlargedFields, normalFields] = partitionBySize(fields);
    const mappedFields = mapFields(fields, enlargedFields, normalFields);

    const dynamicColumns = mappedFields.map(({ field, width }) =>
      createColumn<T>(field, width, renderColumn)
    );

    return [...baseColumns, ...dynamicColumns];
  }, [t, fields, renderCode, renderName, renderColumn]);
}

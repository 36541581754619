import { Icon, IconType } from '@42.nl/ui';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useAuthentication } from '../../authentication/useAuthentication';

type Props = {
  to: string;
  text?: string;
  icon?: IconType;
  iconClass?: string;
};

export function AdminNavLink({ to, text, icon, iconClass }: Readonly<Props>) {
  const auth = useAuthentication();
  const isAdmin = auth?.currentUser?.roles.includes('ADMIN');

  return isAdmin ? (
    <>
      <NavLink
        to={to}
        className="nav-link d-flex align-items-center"
      >
        {icon ? (
          <Icon
            icon={icon}
            className={classNames(iconClass)}
          />
        ) : null}
        {text}
      </NavLink>
    </>
  ) : null;
}

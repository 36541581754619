import Additional from './Additional';
import Appraisal from './Appraisal';
import Capacity from './Capacity';
import Credits from './Credits';
import Description from './Description';
import License from './License';
import Link from './Link';
import MethodSchema from './MethodSchema';
import Objective from './Objective';
import Offering from './Offering';
import Reference from './Reference';
import Relation from './Relation';
import Subject from './Subject';
import Text from './Text';
import Value from './Value';

export default class Data {
  id?: string;
  uid!: string;
  code?: string;
  type?: string;
  key?: number;
  names?: Text[];
  year!: Reference;
  faculty?: Reference;
  organisation?: Reference;
  additional?: Additional;
  capacity?: Capacity;
  credits?: Credits;
  descriptions?: Description[];
  relations?: Relation[];
  objectives?: Objective[];
  offerings?: Offering[];
  licenses?: License[];
  links?: Link[];
  subjects?: Subject[];
  assessments?: Appraisal[];
  methods?: MethodSchema[];
  values?: Value[];
}

export function getDefaultCode(data: Data) {
  return data.code || data.id || data.uid;
}

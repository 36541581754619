import { useMemo } from 'react';
import Widget, { getVisibleSortedFields } from '../Widget';
import WidgetField from '../WidgetField/WidgetField';
import { useWidgetGroups } from './useWidgetGroups';

export function usePrimaryWidgetFields(widgets: Widget[]): WidgetField[] {
  const groupedWidgets = useWidgetGroups(widgets);

  return useMemo(() => {
    return groupedWidgets.primary.flatMap((widget) =>
      getVisibleSortedFields(widget)
    );
  }, [groupedWidgets.primary]);
}

import parse from 'html-react-parser';
import Markdown from 'react-markdown';
import i18n from '../../../core/i18n';
import { getParameter } from '../../parameters/Parameter';
import Reference from './Reference';

export default class Description {
  type?: Reference;
  language?: string;
  text?: string;
}

function isEmpty(string: string) {
  return string.includes('None') || string.includes('Geen');
}

export function getDescriptionText(
  descriptions: Description[],
  noInfo: string
) {
  const description = descriptions.find(
    (description_) => description_.language === i18n.language.toUpperCase()
  );

  if (description && description.text && !isEmpty(description.text)) {
    return render(description.text);
  } else {
    const alternative = descriptions.find(
      (description_) => description_.language !== i18n.language.toUpperCase()
    );

    if (alternative && alternative.text && !isEmpty(alternative.text)) {
      return render(alternative.text, noInfo);
    }
  }
  return;
}

export function render(text: string, noInfo?: string) {
  const markdown = getParameter('description.format', 'html') === 'markdown';

  return (
    <>
      {noInfo ? (
        <p>
          <i>{noInfo}</i>
        </p>
      ) : null}
      {markdown ? <Markdown>{text}</Markdown> : parse(text)}
    </>
  );
}

import { useQuery, UseQueryResult } from 'react-query';
import Widget from '../Widget';

export function useWidgetsOfTab(
  tabId: number | undefined
): UseQueryResult<Widget[]> {
  return useQuery(
    [Widget.QUERY_KEY, { tabId }],
    () => Widget.getVisibleWidgetsForTab(tabId),
    {
      enabled: !!tabId
    }
  );
}

import Additional from './Additional';
import Capacity from './Capacity';
import Credits from './Credits';
import Description from './Description';
import Item from './Item';
import Reference from './Reference';
import Specification from './Specification';
import StudyGroup from './StudyGroup';
import Text from './Text';
import Value from './Value';

export default class Study {
  id?: string;
  uid!: string;
  code?: string;
  year!: Reference;
  faculty?: Reference;
  organisation?: Reference;
  specification?: Specification;
  credits?: Credits;
  capacity?: Capacity;
  names?: Text[];
  descriptions?: Description[];
  additional?: Additional;
  items?: Item[];
  groups?: StudyGroup[];
  study?: Reference;
  type?: string;
  form?: string;
  variant?: string;
  terminated?: boolean;
  values?: Value[];
}

import SimpleGroup from './SimpleGroup';
import SimpleStudy from './SimpleStudy';

export class GroupStudy {
  group!: SimpleGroup;
  study!: SimpleStudy;
}

export function getUniqueKey(groupStudy: GroupStudy) {
  return `${groupStudy.study.data.code}-${groupStudy.group.data.code}`;
}

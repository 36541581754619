import { useTranslation } from 'react-i18next';
import { getLanguages } from '../../../core/i18n';

type QueryName = 'dutchName' | 'englishName';

type Props = {
  className?: string;
};

export default function LanguageSwitcher({ className }: Readonly<Props>) {
  const { i18n } = useTranslation();

  async function onLanguage(language: string) {
    await i18n.changeLanguage(language);
    window.location.reload();
  }

  const languages = getLanguages();
  if (!(languages.length > 1)) {
    return null;
  }

  return (
    <span className="d-flex">
      {languages.map((language) => (
        <button
          key={language}
          onClick={() => onLanguage(language)}
          className={`nav-link ${i18n.language === language ? 'font-lg-strong text-dark' : 'font-lg-normal text-decoration-underline color-primary'} border-0 bg-transparent ${className}`}
        >
          {language.toUpperCase()}
        </button>
      ))}
    </span>
  );
}

export function languageCodeToQueryName(languageCode: string): QueryName {
  return languageCode === 'en' ? 'englishName' : 'dutchName';
}

import { useState } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { GroupExtraModes } from '../GroupExtra';

type Props = {
  current?: GroupExtraModes;
  modes: Mode[];
  onChange: (mode: GroupExtraModes) => void;
  className?: string;
};

type Mode = {
  name: GroupExtraModes;
  label: string;
};

export function ModeSwitcher({
  current,
  modes,
  onChange,
  className
}: Readonly<Props>) {
  const [mode, setMode] = useState(current);

  function onClick(clickedMode: GroupExtraModes) {
    setMode(clickedMode);
    onChange(clickedMode);
  }

  return (
    <Nav
      tabs
      className={`d-print-none ${className}`}
    >
      {modes.map((it) => (
        <NavItem key={it.name}>
          <NavLink
            className={classnames({
              active: mode === it.name
            })}
            onClick={() => onClick(it.name)}
          >
            {it.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}

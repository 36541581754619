import { useEffect, useState } from 'react';
import { extend, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckboxMultiSelect from '../../components/CheckboxMultiSelect/CheckboxMultiSelect';
import { getText } from '../../i18n/Text';
import Widget from '../Widget';

type Props = {
  id: string;
  entityType: string | undefined;
};

export default function WidgetSelect({ id, entityType }: Readonly<Props>) {
  const { t } = useTranslation(['widget']);
  const [options, setOptions] = useState<Widget[]>([]);

  useEffect(() => {
    loadWidgets().then((widgets) => {
      const mapped = widgets.map((widget) => {
        let name = getText(widget.labels, widget.name);

        const matches = widget.entityType === entityType;
        if (!matches) {
          name = `(${widget.entityType}) ${name}`;
        }

        return extend(widget, { matches, name, labels: [] });
      });

      setOptions(orderBy(mapped, ['matches', 'name'], ['desc', 'asc']));
    });
  }, [entityType]);

  return (
    <CheckboxMultiSelect
      id={id}
      className="mb-3"
      label={t('widget:ADD_WIDGET')}
      options={options}
    />
  );
}

async function loadWidgets(): Promise<Widget[]> {
  return await Widget.list();
}

import { useEffect, useState } from 'react';
import Reference from './Reference';
import { getReferenceService } from './ReferenceService';

type Result = {
  references: Reference[];
};

export function useReferences(
  type?: string,
  year?: string,
  visible?: boolean
): Result {
  const [references, setReferences] = useState<Reference[]>();

  useEffect(() => {
    let mounted = true;

    const fetchReferences = async () => {
      if (mounted && type) {
        const activeReferences =
          await getReferenceService().getActiveReferences(type, year);
        const filtered = activeReferences.filter((reference: Reference) =>
          visible ? reference.visible === visible : true
        );
        setReferences(filtered);
      }
    };

    fetchReferences();

    return () => {
      mounted = false;
    };
  }, [type, year, visible]);

  return { references: references || [] };
}

import { urlBuilder } from '@42.nl/react-url';
import { makeResource, post } from '@42.nl/spring-connect';
import { isEmpty } from 'lodash';
import { matchesRegex } from '../../core/validation/validation';
import { FACULTY_APP_URL } from '../faculties/FacultyLinks';
import { FILTER_REGEX } from '../filters/Filters';
import { Text } from '../i18n/Text';
import { QUALIFICATION_APP_URL } from '../qualifications/QualificationLinks';
import { STUDY_APP_URL } from '../studies/StudyLinks';
import { GROUP_APP_URL } from '../types/GroupLinks';
import { MODULE_APP_URL } from '../types/ModuleLinks';
import { ProductType } from '../types/Product';
import Widget from '../widgets/Widget';

const baseUrl = '/api/tabs';

export default class Tab extends makeResource<Tab>(baseUrl) {
  id!: number;
  entityType!: ProductType;
  name!: string;
  sequence!: number;
  labels!: Text[];
  grouping?: string;
  modes!: string; // e.g. list,table or table (list by default, first mode is default)
  filter?: string; // e.g. type=BA,MA (only 1 key with values supported)
  visible!: boolean;
  parentTabs?: Tab[];
  widgets?: Widget[];
  isActiveMain: boolean = false;

  hasFilter(): boolean {
    const tabFilter = this.filter;
    if (!tabFilter) return false;

    return !isEmpty(tabFilter) && FILTER_REGEX.test(tabFilter);
  }

  getUrl() {
    switch (this.entityType) {
      case 'FACULTY':
        return FACULTY_APP_URL;
      case 'QUALIFICATION':
        return QUALIFICATION_APP_URL;
      case 'STUDY':
        return STUDY_APP_URL;
      case 'GROUP':
        return GROUP_APP_URL;
      case 'MODULE':
        return MODULE_APP_URL;
      default:
        return null;
    }
  }

  isParent(): boolean {
    if (this.parentTabs === undefined) {
      return false;
    }

    return !this.parentTabs.length;
  }

  static async importYears(years: string[]) {
    const url = urlBuilder({
      url: baseUrl,
      queryParams: { years }
    });
    return post(url, null);
  }

  static readonly QUERY_KEY = 'tabs';
}

export enum TabModeEnum {
  LIST = 'list',
  TABLE = 'table'
}

export type TabMode = TabModeEnum.LIST | TabModeEnum.TABLE;

export function tabModesValidator(name: string, example: string) {
  const TAB_MODES_REGEX = /^(list|table)(,(list|table))?$/;

  return matchesRegex(TAB_MODES_REGEX, name, example);
}

import { filter, orderBy } from 'lodash';
import { useLocation } from 'react-router-dom';
import { parseFilter } from '../../../filters/Filters';
import Tab, { TabMode, TabModeEnum } from '../../Tab';

type ScoredTab = {
  tab: Tab;
  isMainTab: boolean;
  isTypeInPath: boolean;
  matchingFiltersScore: number;
};

export function getActiveMainTab(
  sortedTabs: Tab[],
  location: ReturnType<typeof useLocation>
): Tab | undefined {
  const { search } = location;
  const searchParams = new URLSearchParams(search);

  const scoredTabs: ScoredTab[] = sortedTabs.map((tab) => ({
    tab,
    isMainTab: isMainTabIncludedInPath(searchParams, tab),
    isTypeInPath: isTabUrlIncludedInPath(tab),
    matchingFiltersScore: getMatchingFiltersScore(tab, searchParams)
  }));

  const validTabs = filter(scoredTabs, { isTypeInPath: true });

  return getBestTab(validTabs);
}

/**
 * - Negative (-X): X filter values are missing or extra, penalize mismatches.
 * - Zero (0): No filters defined, a natural fallback option.
 * - Positive (X): X filter values match, favor more specific tabs.
 */
function getMatchingFiltersScore(
  tab: Tab,
  searchParams: URLSearchParams
): number {
  if (!tab.filter || !tab.hasFilter()) {
    return 0;
  }

  const parsedFilter = parseFilter(tab.filter);
  const keys = Object.keys(parsedFilter);
  const key = keys[0] ?? '';

  const searchValues = searchParams.getAll(key);
  const matchingValues = parsedFilter[key].filter((filterValue) =>
    searchValues.includes(filterValue)
  ).length;

  const missingValues = parsedFilter[key].length - matchingValues;
  const extraValues = searchValues.length - parsedFilter[key].length;

  if (missingValues > 0) {
    return -missingValues;
  }

  if (extraValues > 0) {
    return -extraValues;
  }

  return matchingValues;
}

function getBestTab(tabs: ScoredTab[]): Tab | undefined {
  const iteratees: Array<keyof ScoredTab> = [
    'isMainTab',
    'matchingFiltersScore'
  ];
  const sortedTabs = orderBy(tabs, iteratees, ['desc', 'desc']);

  const bestTab = sortedTabs[0];
  return bestTab ? bestTab.tab : undefined;
}

// Needed for backwards compatibility and single-faculty scenarios
function isMainTabIncludedInPath(searchParams: URLSearchParams, tab: Tab) {
  const mainTab = searchParams.get('mainTab');
  return mainTab === tab.name;
}

function isTabUrlIncludedInPath(tab: Tab): boolean {
  if (!tab) {
    return false;
  }

  const currentPath = window.location.pathname;
  const url = tab.getUrl();
  if (!url) return false;

  return url && currentPath.includes(url);
}

export function getTabModes(modes: string | undefined): TabMode[] {
  // No mode suggests a new tab, built with WidgetType.PRIMARY
  if (!modes) {
    return [TabModeEnum.LIST, TabModeEnum.TABLE];
  }

  return modes.split(',') as TabMode[];
}

import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Parameter, { loadParameters } from './Parameter';

type Props = {
  children: React.ReactNode;
  onLoad?: (() => void)[];
};

export function ParameterProvider({ children, onLoad }: Readonly<Props>) {
  const [parameters, setParameters] = useState<Parameter[]>();

  const state = useQuery('parameters', loadParameters);

  useEffect(() => {
    if (state.data) {
      setParameters(state.data);
      if (onLoad && onLoad.length > 0) {
        onLoad.forEach((callback) => {
          callback();
        });
      }
    }
  }, [state.data, onLoad]);

  return <>{parameters !== undefined ? children : null}</>;
}

import React from 'react';
import { Table as AntdTable, TableProps } from 'antd';
import classNames from 'classnames';

export interface TableRecord {
  key: React.Key;
}

export function Table<RecordType extends TableRecord = TableRecord>(
  props: Readonly<TableProps<RecordType>>
) {
  return (
    <AntdTable
      scroll={{ x: 'max-content' }} // overflow-x: auto
      tableLayout="auto"
      rowClassName={classNames('font-base-normal', {
        'cursor--pointer': props.onRow
      })}
      rowHoverable={true}
      size={'small'}
      {...props}
    />
  );
}

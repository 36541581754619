import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';

type Props = {
  selected: string;
  values: string[];
  onSelect: (value: string) => void;
};

export default function EnumSelect({
  selected,
  values,
  onSelect
}: Readonly<Props>) {
  const { t } = useTranslation(['enum', 'translation']);

  return (
    <Input
      type="select"
      value={selected}
      onChange={(event) => onSelect(event.target.value)}
    >
      <option value={''}>{t('translation:SHOW_ALL')}</option>
      {values.map((value) => (
        <option
          key={value}
          value={value}
        >
          {value}
        </option>
      ))}
    </Input>
  );
}

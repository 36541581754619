import { InfoTooltip } from '@42.nl/ui';
import { Label } from 'reactstrap';

type Props = {
  name: string;
  label?: string;
  tooltip?: string;
  className?: string;
};

export function FilterLabel({
  name,
  label,
  tooltip,
  className
}: Readonly<Props>) {
  return (
    <Label
      for={name}
      className={className}
    >
      {label}
      {tooltip ? (
        <InfoTooltip
          tooltip={tooltip}
          className="ms-1 text-primary"
          size={15}
        />
      ) : null}
    </Label>
  );
}

import { makeResource } from '@42.nl/spring-connect';
import { UserRole } from './CurrentUser';

export default class User extends makeResource<User>('/api/users') {
  id!: number;
  name?: string;
  externalId?: string;
  email?: string;
  password!: string;
  endDate?: Date;
  roles!: UserRole[];
}

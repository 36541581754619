import { useEffect, useState } from 'react';
import { Page } from '@42.nl/spring-connect';
import { EpicTableSortDirection } from '@42.nl/ui';
import { default as classnames, default as classNames } from 'classnames';
import { some } from 'lodash';
import { Table } from 'reactstrap';
import { PaginationBar } from '../PaginationBar/PaginationBar';
import Sort, { getSort, SortOptions } from '../Sort/Sort';

interface BaseProps<T> {
  columns: Column<T>[];
  width?: number;
  className?: string;
  highlightSelected?: boolean;
  selectFirst?: boolean;
  overlay?: () => React.ReactNode;
  onClick?: (row: T) => void;
}

interface PageProps<T> extends BaseProps<T> {
  page: Page<T>;
  sort: string;
  onSort: (sort: string) => void;
  onPage: (page: number, size: number) => void;
}

interface ListProps<T> extends BaseProps<T> {
  list: T[];
}

type Props<T> = PageProps<T> | ListProps<T>;

type Column<T> = {
  label: string;
  sort?: string;
  width?: number;
  truncate?: boolean;
  className?: string;
  filter?: () => React.ReactNode;
  cell?: (row: T, index: number) => React.ReactNode;
};

export function DataTable<T>(props: Props<T>) {
  const {
    columns,
    width,
    className,
    highlightSelected,
    selectFirst,
    overlay,
    onClick
  } = props;
  const [selectedRow, setSelectedRow] = useState<number>();

  const filtered = some(columns, (column) => !!column.filter);

  const listProps = props as unknown as ListProps<T>;
  const pageProps = props as unknown as PageProps<T>;

  const currentPage =
    listProps.list !== undefined ? pageOf(listProps.list) : pageProps.page;
  const isPaged = pageProps.page !== undefined;

  function getWidth(column: Column<T>) {
    const columnWidth = `${
      column.width !== undefined ? column.width : width || 0
    }px`;

    if (column.truncate) {
      return {
        maxWidth: columnWidth
      };
    }
    return {
      minWidth: columnWidth
    };
  }

  useEffect(() => {
    if (selectFirst && !selectedRow) {
      if (isPaged) {
        onRowClick(pageProps.page.content[0], 0);
      } else {
        onRowClick(listProps.list[0], 0);
      }
    }
  });

  function onRowClick(row: T, index: number) {
    if (onClick) {
      onClick(row);
      setSelectedRow(index);
    }
  }

  const sort: SortOptions = isPaged
    ? getSort(pageProps.sort)
    : { column: '', direction: 'NONE' };

  function onSort(column: string, direction: EpicTableSortDirection) {
    pageProps.onSort(`${column},${direction}`);
  }

  return (
    <div>
      <Table
        responsive
        striped
        size="sm"
        className={classNames(className, 'mb-0')}
      >
        <thead>
          <tr className="table-header">
            {columns.map((column, index) => (
              <th
                key={`header-${column.label}-${index}`}
                style={getWidth(column)}
              >
                <span className="text-nowrap">
                  <span>{column.label}</span>
                  {column.sort && isPaged ? (
                    <Sort
                      column={column.sort}
                      sort={sort}
                      onChange={onSort}
                    />
                  ) : null}
                </span>
              </th>
            ))}
          </tr>
          {filtered ? (
            <tr className="table-filter">
              {columns.map((column, index) => (
                <th key={`filter-${column.label}-${index}`}>
                  {column.filter ? column.filter() : null}
                </th>
              ))}
            </tr>
          ) : null}
        </thead>

        <tbody>
          {overlay && currentPage.totalElements === 0 ? (
            <tr>
              <td colSpan={999}>{overlay()}</td>
            </tr>
          ) : null}

          {currentPage.content.map((row, rowIndex) => (
            <tr
              key={`row-${rowIndex}`}
              onClick={() => onRowClick(row, rowIndex)}
              className={classNames({
                clickable: onClick !== undefined,
                'table-active': rowIndex === selectedRow && highlightSelected
              })}
            >
              {columns.map((column, columnIndex) => (
                <td
                  key={`cell-${rowIndex}-${columnIndex}`}
                  className={classnames(
                    { 'text-truncate': column.truncate },
                    column.className
                  )}
                  style={getWidth(column)}
                >
                  {column.cell ? column.cell(row, rowIndex) : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {isPaged ? (
        <PaginationBar
          page={currentPage}
          onChange={({ page, size }) => pageProps.onPage(page, size)}
        />
      ) : null}
    </div>
  );
}

function pageOf<T>(content: Array<T>): Page<T> {
  return {
    content,
    last: true,
    totalElements: content.length,
    totalPages: 1,
    size: content.length,
    number: 1,
    first: true,
    numberOfElements: content.length
  };
}

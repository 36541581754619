import { AuthenticationState, getService } from '@42.nl/authentication';
import { get } from '@42.nl/spring-connect';
import { CurrentUser, getCurrent, getToken } from '../users/CurrentUser';

const CONFIG_URL = '/api/authentication/config';

export interface AuthenticationConfig {
  configId?: string;
  customerSignature?: string;
  defaultLoginUrl: string | null;
  environment?: string;
  registrations: Registration[];
}

export interface Registration {
  id: string;
  label?: string;
  url: string;
}

export async function getConfig(): Promise<AuthenticationConfig> {
  const isLoggedIn = await getIsLoggedIn();

  // No need to fetch the saml config when the user is already logged in
  if (isLoggedIn) {
    return Promise.resolve({
      defaultLoginUrl: null,
      registrations: []
    });
  }

  // If anything goes wrong go to the normal login
  return get<AuthenticationConfig>(CONFIG_URL).catch(() => {
    return Promise.resolve({
      defaultLoginUrl: null,
      registrations: []
    });
  });
}

async function getIsLoggedIn(): Promise<boolean> {
  try {
    await getCurrent();
    return true;
  } catch {
    return false;
  }
}

class AuthenticationService {
  private authentication: AuthenticationState<CurrentUser> = {
    isLoggedIn: false
  };

  private loaded = false;
  private initialized = false;

  constructor() {
    this.getAuthentication = this.getAuthentication.bind(this);
    this.initialize = this.initialize.bind(this);
  }

  initialize() {
    if (!this.initialized) {
      const service = getService<CurrentUser>();

      service.subscribe((nextState: AuthenticationState<CurrentUser>) => {
        this.authentication = nextState;
      });

      this.initialized = true;
    }
  }

  async getAuthentication() {
    if (!this.loaded) {
      try {
        const currentUser = await getCurrent();
        this.setAuthentication(currentUser);

        this.loaded = true;
      } catch (error: any) {
        if (error.response.status === 401) {
          this.loaded = true;
        }
      }
    }
    return this.authentication;
  }

  setAuthentication(currentUser: CurrentUser | undefined) {
    this.authentication.currentUser = currentUser;
    this.authentication.isLoggedIn = currentUser !== undefined;
  }

  async authenticateToken(token: string): Promise<CurrentUser | undefined> {
    const currentUser = await getToken(token);
    this.setAuthentication(currentUser);

    return currentUser;
  }
}

const instance = new AuthenticationService();

export function getAuthenticationService(): AuthenticationService {
  return instance;
}

import { capitalize, isEmpty } from 'lodash';
import { getText, Text } from '../../i18n/Text';
import { FieldType } from '../Widget';

export default class WidgetField {
  id!: number;
  type!: FieldType;
  name!: string;
  filter?: string;
  format?: string;
  referenceType?: string;
  sequence!: number;
  visible!: boolean;
  hideEmpty!: boolean;
  admin!: boolean;
  labels!: Text[];
  tooltips!: Text[];
}

export function getWidgetFieldKey(field: WidgetField) {
  return `field-${field.id}`;
}

export function getWidgetFieldLabel(field: WidgetField) {
  return getText(field.labels, capitalize(field.name));
}

export function widgetFieldHasFormat(field: WidgetField) {
  return field.format && !isEmpty(field.format);
}

import { useState } from 'react';
import { ExpandAll } from '../../../components/ExpandAll/ExpandAll';
import { GroupContents } from '../../../groups/GroupContents/GroupContents';
import { ReferenceType } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import Product from '../../../types/Product';
import SimpleGroup, { sortGroups } from '../../../types/SimpleGroup';
import SimpleStudy from '../../../types/SimpleStudy';
import Widget from '../../Widget';

type Props = {
  widget: Widget;
  product: Product;
  expanded: boolean;
};

export function StructureWidget({
  widget,
  product,
  expanded
}: Readonly<Props>) {
  const { references: types } = useReferences(ReferenceType.GROUP_TYPE);
  const [expandAll, setExpandAll] = useState(expanded);

  const toggleExpandAll = (expand: boolean) => {
    setExpandAll(expand);
  };

  if (product instanceof SimpleStudy) {
    return (
      <div className="structure">
        <ExpandAll
          expand={expandAll}
          className="pt-3 pb-2"
          onToggle={toggleExpandAll}
        />

        {sortGroups(product.data.groups, types).map((group, index) =>
          group.group ? (
            <GroupContents
              key={`group-${index}`}
              group={group.group}
              year={product.data.year.id}
              expandAll={expandAll}
              fields={widget.fields}
            />
          ) : null
        )}
      </div>
    );
  } else if (product instanceof SimpleGroup) {
    return (
      <div className="structure">
        <GroupContents
          key="group"
          level={0}
          group={product.data}
          year={product.data.year.id}
          expandAll={expandAll}
          fields={widget.fields}
        />
      </div>
    );
  }

  return null;
}

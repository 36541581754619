import { useTranslation } from 'react-i18next';
import { useActiveMainTab } from '../tabs/context/hooks/useActiveMainTab';
import Tab from '../tabs/Tab';
import Product from '../types/Product';
import { WidgetDetail } from '../widgets/WidgetDetail/WidgetDetail';
import ProductHeader from './ProductHeader/ProductHeader';

type Props = {
  tabs: Tab[];
  product: Product;
};

export function ProductPrint({ tabs, product }: Readonly<Props>) {
  const { t } = useTranslation('translation');
  const activeTab = useActiveMainTab();

  return (
    <>
      <ProductHeader
        product={product}
        tabId={activeTab?.id}
      />
      <h2 className="mt-3">
        {t('YEAR')}: {product.data.year.code}
      </h2>
      {tabs.map((tab) => (
        <WidgetDetail
          key={tab.id}
          tabId={tab.id}
          product={product}
          expanded={true}
          includeWidget={(widget) => widget.showOnPrint}
        />
      ))}
    </>
  );
}

import { useEffect, useState } from 'react';
import { useClearErrors } from '@42.nl/react-error-store';
import {
  Button,
  JarbCheckbox,
  JarbInput,
  JarbSelect,
  SubmitButton
} from '@42.nl/ui';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import CheckboxMultiSelect from '../components/CheckboxMultiSelect/CheckboxMultiSelect';
import { filterValidator } from '../filters/Filters';
import { Text } from '../i18n/Text';
import TextInput from '../i18n/TextInput';
import { PRODUCT_TYPES } from '../types/Product';
import Widget from '../widgets/Widget';
import WidgetSelect from '../widgets/WidgetSelect/WidgetSelect';
import Tab, { TabModeEnum, tabModesValidator } from './Tab';

export type TabFormData = {
  id?: number;
  entityType?: string;
  name?: string;
  sequence?: number;
  labels: Text[];
  grouping?: string;
  filter?: string;
  visible?: boolean;
  parentTabs?: Tab[];
  widgets?: Widget[];
};

type Props = {
  initialValues: TabFormData;
  type: string;
  entityType: string;
  onCancel: () => void;
  onSubmit: (data: TabFormData) => void;
};

export default function TabForm(props: Readonly<Props>) {
  const { t } = useTranslation(['tab', 'translation']);

  const { onCancel, onSubmit, initialValues } = props;

  const [allTabs, setAllTabs] = useState<Tab[]>([]);
  const [tabOptions, setTabOptions] = useState<Tab[]>([]);

  useEffect(() => {
    if (initialValues.entityType) {
      loadTabs().then((tabs) => {
        setAllTabs(tabs);
        setTabOptions(
          tabs.filter(
            (tab) =>
              tab.entityType === initialValues.entityType &&
              !tab.parentTabs?.length &&
              initialValues?.id !== tab.id
          )
        );
      });
    }
  }, [initialValues]);

  async function loadTabs(): Promise<Tab[]> {
    return await Tab.list();
  }

  function loadOptions(entityType: string) {
    if (entityType !== undefined) {
      if (!allTabs.length) {
        loadTabs().then((tabs) => {
          setAllTabs(tabs);
          setTabOptions(
            tabs.filter(
              (tab) => tab.entityType === entityType && !tab.parentTabs?.length
            )
          );
        });
      }

      setTabOptions(
        allTabs.filter(
          (tab) => tab.entityType === entityType && !tab.parentTabs?.length
        )
      );
    } else {
      setTabOptions([]);
    }
  }

  // Clear previous back-end errors if this form was shown before
  useClearErrors();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <JarbInput
            id="name"
            name="name"
            type="text"
            jarb={{
              validator: 'Tab.name',
              label: t('tab:COLUMNS.NAME')
            }}
            label={t('tab:COLUMNS.NAME')}
          />

          <JarbSelect
            id="entityType"
            name="entityType"
            label={t('tab:COLUMNS.ENTITY_TYPE')}
            placeholder={t('tab:ENTITY_TYPE_PLACEHOLDER')}
            options={PRODUCT_TYPES}
            labelForOption={(type) => type}
            jarb={{
              validator: 'Tab.entityType',
              label: t('tab:COLUMNS.ENTITY_TYPE')
            }}
          />

          <OnChange name="entityType">
            {(entityType) => {
              loadOptions(entityType);
            }}
          </OnChange>

          <CheckboxMultiSelect
            id="parentTabs"
            className="mb-3"
            label={t('tab:PARENT_PLACEHOLDER')}
            options={tabOptions}
          />

          <WidgetSelect
            id="widgets"
            entityType={initialValues.entityType}
          />

          <TextInput
            id="labels"
            label={t('tab:COLUMNS.LABEL')}
          />

          <JarbInput
            id="sequence"
            name="sequence"
            type="number"
            jarb={{
              validator: 'Tab.sequence',
              label: t('tab:COLUMNS.SEQUENCE')
            }}
            label={t('tab:COLUMNS.SEQUENCE')}
          />

          <JarbInput
            id="filter"
            name="filter"
            type="text"
            jarb={{
              validator: 'Tab.filter',
              label: t('tab:COLUMNS.FILTER')
            }}
            label={t('tab:COLUMNS.FILTER')}
            validators={[
              filterValidator(t('tab:COLUMNS.FILTER'), 'type=MA,BA')
            ]}
          />

          <JarbInput
            id="modes"
            name="modes"
            type="text"
            jarb={{
              validator: 'Tab.modes',
              label: t('tab:COLUMNS.MODES')
            }}
            defaultValue={TabModeEnum.LIST}
            label={t('tab:COLUMNS.MODES')}
            validators={[
              tabModesValidator(t('tab:COLUMNS.MODES'), 'list,table')
            ]}
          />

          <JarbCheckbox
            id="visible"
            name="visible"
            className="mb-3"
            jarb={{
              validator: 'Tab.visible',
              label: t('tab:COLUMNS.VISIBLE')
            }}
            label={t('tab:COLUMNS.VISIBLE')}
          />

          <div className="d-flex justify-content-end gap-2">
            <Button
              onClick={onCancel}
              color="secondary"
            >
              {t('translation:CANCEL')}
            </Button>
            <SubmitButton inProgress={submitting}>
              {t('translation:SUBMIT_BUTTON')}
            </SubmitButton>
          </div>
        </form>
      )}
    />
  );
}

import { login } from '@42.nl/authentication';
import { loadConstraints } from '@42.nl/jarb-final-form';
import { loadEnums } from '@42.nl/react-spring-enums';
import { getFilterService } from '../../../filters/FilterService';
import { getPeriodService } from '../../../periods/PeriodService';
import { getReferenceService } from '../../../references/ReferenceService';
import { getCurrent } from '../../../users/CurrentUser';
import { getYearService } from '../../../years/YearService';

/**
 * The Login starts at the `AUTOLOGIN` phase. In this phase
 * the login flow will check if the user is already logged in,
 * if so it will move to phase `LOGIN_SUCCESS`. The user is already
 * logged in when his / her session with the back-end is still valid.
 *
 * If the autologin fails the state will move to `SHOW_LOGIN_FORM`,
 * and will make the user log into the application.
 *
 * If the user enters invalid credentials the state will go to
 * `SHOW_LOGIN_FORM_WITH_ERRORS`, which will show the Error to
 * the user for a limited time. It will automatically transition
 * back to `SHOW_LOGIN_FORM` after a certain duration.
 *
 * If the user enters empty credentials the state will go to
 * `SHOW_LOGIN_FORM_WITH_ERRORS` immediately.
 *
 * If the credentials are valid it will go to `LOGIN_SUCCESS`. This will
 * load the constraints and the enums. If this succeeds the flow
 * is finished.
 *
 * If anything goes wrong which is unexpected the state moves to
 * `UNEXPECTED_ERROR`. These errors are likely developer errors, these
 * can occur when the back-end stops responding with the expected
 * JSON responses.
 *
 * The state diagram looks like this, `UNEXPECTED_ERROR` is not shown.
 * Steps wich can result in an unexpected error have an `*` next to
 * them.
 *
 *                         session valid
 * AUTOLOGIN* -------------------------------> LOGIN_SUCCESS*
 *      |                                           ^
 *      |                                           |
 *      | session invalid                           | valid credentials
 *      |                                           |
 *      |---> SHOW_LOGIN_FORM ---> LOGIN_PENDING* --|
 *                 ^   |                |
 * after a timeout |   |                |
 *                 |   V                | Invalid credentials
 *      SHOW_LOGIN_FORM_WITH_ERRORS < --|
 */
export enum Phase {
  AUTOLOGIN = 'AUTOLOGIN',
  ALREADY_LOGGED_IN = 'ALREADY_LOGGED_IN',
  SHOW_LOGIN_FORM = 'SHOW_LOGIN_FORM',
  LOGIN_PENDING = 'LOGIN_PENDING',
  SHOW_LOGIN_FORM_WITH_ERRORS = 'SHOW_LOGIN_FORM_WITH_ERRORS',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR'
}

/**
 * The messages which can be shown to the user,
 * are keys in the translations in login.json.
 */
export enum Message {
  INSTRUCTION = 'INSTRUCTION',
  USERNAME_PASSWORD_INCORRECT = 'USERNAME_PASSWORD_INCORRECT',
  TOO_MANY_LOGIN_ATTEMPTS = 'TOO_MANY_LOGIN_ATTEMPTS',
  USERNAME_PASSWORD_REQUIRED = 'USERNAME_PASSWORD_REQUIRED'
}

export type State = {
  phase: Phase;
  message: Message;
};

type SetState = (state: State) => void;

// This is done for testing purposes so we can mock the functions.
export const transitions = {
  autologinPending,
  alreadyLoggedIn,
  showLoginForm,
  showLoginFormWithErrors,
  loginPending,
  loginSuccess,
  unexpectedError
};

export async function autologinPending(setState: SetState): Promise<boolean> {
  setState({ phase: Phase.AUTOLOGIN, message: Message.INSTRUCTION });

  try {
    await getCurrent();
    return transitions.alreadyLoggedIn(setState);
  } catch (error: any) {
    if (error.response.status === 401) {
      return transitions.showLoginForm(setState);
    } else {
      return transitions.unexpectedError(setState, error);
    }
  }
}

export async function showLoginForm(setState: SetState): Promise<boolean> {
  setState({ phase: Phase.SHOW_LOGIN_FORM, message: Message.INSTRUCTION });
  return true;
}

export async function loginPending(
  username: string,
  password: string,
  setState: SetState
): Promise<boolean> {
  // Don't bother sending without a username or password.
  if (username === '' || password === '') {
    return transitions.showLoginFormWithErrors(
      setState,
      Message.USERNAME_PASSWORD_REQUIRED
    );
  }

  setState({ phase: Phase.LOGIN_PENDING, message: Message.INSTRUCTION });

  try {
    await login({ username, password });
    return transitions.loginSuccess(setState);
  } catch (error: any) {
    return transitions.showLoginFormWithErrors(setState, error);
  }
}

type LoginErrorResponse = {
  status: number;
  json: () => Promise<{ errorCode: Message }>;
};

let messageTimeout = -1;

export async function showLoginFormWithErrors(
  setState: SetState,
  error: LoginErrorResponse | Message
): Promise<boolean> {
  window.clearTimeout(messageTimeout);

  let message = Message.INSTRUCTION;
  let visibilityInMs = 2500;

  if (typeof error === 'string') {
    message = error;
  } else if (error.status === 401) {
    message = Message.USERNAME_PASSWORD_INCORRECT;
  } else {
    try {
      const json = await error.json();

      message = json.errorCode;

      visibilityInMs = 60000;
    } catch (caughtError) {
      return transitions.unexpectedError(setState, caughtError);
    }
  }

  setState({ phase: Phase.SHOW_LOGIN_FORM_WITH_ERRORS, message });

  messageTimeout = window.setTimeout(() => {
    transitions.showLoginForm(setState);
  }, visibilityInMs);

  return Promise.resolve(false);
}

export function bootApp() {
  return Promise.all([
    loadConstraints(),
    loadEnums(),
    getReferenceService().loadReferences(),
    getYearService().loadYears(),
    getPeriodService().loadPeriods(),
    getFilterService().loadAllFilters()
  ]);
}

export async function alreadyLoggedIn(setState: SetState): Promise<boolean> {
  setState({ phase: Phase.ALREADY_LOGGED_IN, message: Message.INSTRUCTION });
  return true;
}

export async function loginSuccess(setState: SetState): Promise<boolean> {
  // LOGIN_SUCCESS is an odd duck because it only sets its state to
  // LOGIN_SUCCESS after it has done its job. The reason for this is
  // because otherwise we would have needed two extra states:
  // BOOT_APP_PENDING and BOOT_APP_SUCCESS, to indicate that we are
  // booting the app. Since the UI would do nothing with those state's
  // the have not been added.

  try {
    // Set to LOGIN_SUCCESS after app is booted
    setState({ phase: Phase.LOGIN_SUCCESS, message: Message.INSTRUCTION });

    return true;
  } catch (error) {
    return transitions.unexpectedError(setState, error);
  }
}

export async function unexpectedError(
  setState: SetState,
  error: any
): Promise<boolean> {
  setState({ phase: Phase.UNEXPECTED_ERROR, message: Message.INSTRUCTION });
  // eslint-disable-next-line no-console
  console.error(error);
  return false;
}

import { get, makeResource } from '@42.nl/spring-connect';
import _, { find, isEmpty } from 'lodash';
import { useQuery, UseQueryResult } from 'react-query';
import { Text } from '../i18n/Text';
import Reference, { displayName } from '../references/Reference';
import { useActiveMainTab } from '../tabs/context/hooks/useActiveMainTab';
import Value from '../types/canonical/Value';
import { ProductType } from '../types/Product';
import WidgetField from './WidgetField/WidgetField';

const baseUrl = '/api/widgets';

export default class Widget extends makeResource<Widget>(baseUrl) {
  id!: number;
  entityType!: ProductType;
  name!: string;
  filter?: string;
  sequence!: number;
  visible!: boolean;
  showOnPrint!: boolean;
  hideHeader!: boolean;
  type!: WidgetType;
  labels!: Text[];
  fields!: WidgetField[];

  static async getVisibleWidgetsForTab(
    tabId: number | undefined
  ): Promise<Widget[]> {
    if (tabId === undefined) {
      return Promise.resolve([]);
    }

    const widgets = await get<Widget[]>(baseUrl, { tabId });
    return _(widgets).filter('visible').sortBy(['sequence', 'id']).value();
  }

  static readonly QUERY_KEY = 'widgets';
}

export function getVisibleSortedFields(widget: Widget) {
  return _(widget.fields)
    .filter('visible')
    .sortBy(['sequence', 'name', 'id'])
    .value();
}

export function getValue(
  field: WidgetField,
  values: Value[],
  references: Reference[]
) {
  const value = find(values, { name: field.name });
  if (!value) {
    return '';
  }

  if (!isEmpty(value.description)) {
    return value.description;
  }

  return translate(value.values, references);
}

export function translate(values: any[], references: Reference[]) {
  return values
    .map((value) => {
      if (value instanceof Date) {
        return value.toLocaleDateString();
      }

      return displayName(value, references, value);
    })
    .sort()
    .join(', ');
}

export enum WidgetType {
  APPRAISALS = 'APPRAISALS',
  BUTTONS = 'BUTTONS',
  CONTAINER = 'CONTAINER',
  PRIMARY = 'PRIMARY',
  SIDEBAR = 'SIDEBAR',
  STRUCTURE = 'STRUCTURE'
}

export type FieldType =
  | 'ADDITIONAL'
  | 'ASSESSMENTS'
  | 'CUSTOM'
  | 'DESCRIPTION'
  | 'GROUPS'
  | 'LICENSES'
  | 'LINKS'
  | 'METHODS'
  | 'OBJECTIVES'
  | 'PERIODS'
  | 'PERIODS_VALUE'
  | 'RELATION'
  | 'RULES'
  | 'SUBJECTS'
  | 'URL'
  | 'VALUE';

export function useQuickViewWidgets(): UseQueryResult<Widget[]> {
  const tab = useActiveMainTab();

  return useQuery([Widget.QUERY_KEY, tab?.id], () => {
    return Widget.getVisibleWidgetsForTab(tab?.id);
  });
}
